import { useEffect, useRef, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import ApiChannels from 'assets/js/classes/ApiChannels'
import ApiEvents from 'assets/js/classes/ApiEvents'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import BucketsClass from 'assets/js/classes/Buckets'
// import DragAndDrop from 'assets/js/classes/DragAndDrop'
import DragDrop from 'assets/js/classes/DragDrop'
import { AxiosMethods } from 'assets/js/utilities/axios'
import eventTaskUpdated from 'assets/js/utilities/eventTaskUpdated'
import SearchBar from 'components/forms/SearchBar'
import { StrictModeDroppable } from 'components/ui/StrictModeDroppable'
import { stateLogin, useAuthDispatch, useAuthState } from 'contexts/AuthContext'
import { stateUpdateDashboard, useDashboardDispatch, useDashboardState } from 'contexts/DashboardContext'
import { stateUpdateSearchBar, useSearchBarDispatch, useSearchBarState } from 'contexts/SearchBarContext'
import { updateTenant, useTenantDispatch, useTenantState } from 'contexts/TenantContext'
import useApiCall from 'hooks/useApiCall'
import useWebSocket from 'hooks/useWebSocket'
import Title from 'layouts/Main/Title'
import AllProjectsColumn from './Column'
import scrollDrag from '../components/DynamicBoard/scrollDrag'
import '../css/trello.css'
import DashboardSettings from './Settings'
import Dashboard from 'assets/js/classes/Dashboard'

class DropZoneHeight {
  static HEIGHT_THRESHOLD = 5;
  static MARGIN = 8;  // 8 compensates for bottom margin my-2
}

export default function AllProjects() {
  // CONTEXT PARAMS - AUTH USER
  const { user } = useAuthState();

  // CONTEXT PARAMS - TENANT
  const { tenant } = useTenantState();

  // CONTEXT PARAMS - DASHBOARD
  const { buckets, bucketsRef, scrollSnap } = useDashboardState();
  const dashboardDispatch = useDashboardDispatch();
  
  // CONTEXT - SEARCH BAR
  const { dataset, datasetRef } = useSearchBarState();
  const searchBarDispatch = useSearchBarDispatch();

  // STATE PARAMS
  const [loading, setLoading] = useState(true);

  const onDragEnd = ({ destination, source, type, draggableId }) => {
    stateUpdateDashboard(dashboardDispatch, { scrollSnap: true });  // ENABLE SCROLL SNAP

    const restructuredData = DragDrop.onDragEnd({ destination, source, type, draggableId }, { buckets, dataset });
    
    if(!restructuredData) return;
    
    const { newBuckets, newDataset } = restructuredData;
    
    stateUpdateDashboard(dashboardDispatch, { buckets: newBuckets });
    stateUpdateSearchBar(searchBarDispatch, { dataset: newDataset });
  }
  
  const onDragStart = () => stateUpdateDashboard(dashboardDispatch, { scrollSnap: false });  // DISABLE SCROLL SNAP

  // BUCKET HEIGHT
  const handleResize = () => {
    const dz = document.getElementsByClassName('bucketDropZone');
    Object.values(dz).forEach(dz => {
      const maxHeight = window.innerHeight - dz.getBoundingClientRect().top - DropZoneHeight.MARGIN;
      dz.style.maxHeight = `${maxHeight}px`;  // 8 compensates for bottom margin my-2
      dz.style.overflowY = dz.getBoundingClientRect().height < (maxHeight - DropZoneHeight.HEIGHT_THRESHOLD) ? 'hidden' : 'scroll';
    });
  }
  window.addEventListener('resize', handleResize);
  useEffect(handleResize);

  useApiCall({
    apiMethod: AxiosMethods.GET,
    apiRoute: ApiRoutes.BUCKETS,
    callback: ({ buckets }) => {
      stateUpdateDashboard(dashboardDispatch, { buckets });
      stateUpdateSearchBar(searchBarDispatch, { dataset: buckets });
    },
    setLoading,
  });

  useEffect(() => { bucketsRef.current = buckets }, [buckets]);
  useEffect(() => { datasetRef.current = dataset }, [dataset]);

  useWebSocket(ApiChannels.PRIVATE_TENANT(tenant?.id), [
    {
      events: [ApiEvents.TASK_UPDATED],
      callback: ({ author, task }, toastsContext) => eventTaskUpdated(
        { author, user, task },
        {
          dashboard: {
            dataset: bucketsRef.current,  // https://github.com/facebook/react/issues/14010#issuecomment-433788147
            dispatch: dashboardDispatch,
            prop: 'buckets',
            update: stateUpdateDashboard,
          },
          searchBar: {
            dataset: datasetRef.current,  // https://github.com/facebook/react/issues/14010#issuecomment-433788147
            dispatch: searchBarDispatch,
            prop: 'dataset',
            update: stateUpdateSearchBar,
          },
        }, { ...toastsContext }
      ),
    },
  ]);

  return (
    <div className="app-main__inner p-0 d-flex flex-column" style={{overflow:'auto'}}>
      <Title title="All Projects" subtitle="Dashboard" icon="square-kanban" />

      <Row className="px-3">
        <Col xs={12} sm={2} className="d-flex align-items-center justify-content-center">
          {/* <DynamicBoardSettings /> */}
          <DashboardSettings />
        </Col>
        <Col xs={12} sm={10}>
          <SearchBar 
            dataset={dataset} 
            disabled={loading}
            stateUpdate={stateUpdateDashboard} 
            stateKey="buckets" 
            stateType={Array} 
            dispatch={dashboardDispatch} 
            filterCallback={BucketsClass.filterByQuery} 
          />
        </Col>
      </Row>

      {/* BUCKETS LOADER */}
      {loading &&
        <Row className="flex-nowrap px-3">
          {Array.from(Array(3), (_,i) =>
            <Col key={i} xs={12} md={6} lg={5} xl={4}>
              <Skeleton height={210} />
            </Col>
          )}
        </Row>
      }

      {/* BUCKETS */}
      {!loading && buckets?.length > 0 &&
        <DragDropContext 
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
        >
          <StrictModeDroppable 
            droppableId="allBuckets" 
            direction="horizontal" 
            type={DragDrop.BUCKETS}
          >
            {(provided, snapshot) => (
              <Row 
                className="d-flex flex-nowrap flex-fill px-3" 
                id="trello"
                {...provided.droppableProps}
                ref={provided.innerRef}
                onMouseDown={e => scrollDrag(e, stateUpdateDashboard, dashboardDispatch)}
                style={{ userSelect: 'none', scrollSnapType: scrollSnap ? 'x mandatory' : 'none' }}>

                {buckets.map((bucket, index) => 
                  <AllProjectsColumn 
                    key={bucket.id} 
                    bucket={bucket} 
                    index={index} 
                  />
                )}
                
                {provided.placeholder}
              </Row>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      }

    </div>
  )
}