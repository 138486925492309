import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, ListGroup } from "react-bootstrap"
import { NavLink } from 'react-router-dom'
import Main from "layouts/Main"

export default function Landing() {
  return (
    <Main title="Collaboro" subtitle="Repairware" icon="toolbox">
      <Card>
        <Card.Body className="p-0">
          <ListGroup variant="flush">
            <NavLink end to="./projects" className="text-decoration-none">
              <ListGroup.Item action className="p-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <FontAwesomeIcon 
                    className="mr-4 text-crystal-blue" 
                    fixedWidth 
                    icon={['fad', 'square-kanban']} 
                    size="2x" 
                  />
                  <h5 className="d-inline w-100 m-0 pl-3">Dashboards</h5>
                  <FontAwesomeIcon 
                    className="ml-4 text-crystal-blue" 
                    fixedWidth 
                    icon={['fad', 'arrow-right']} 
                    size="2x" 
                  />
                </div>
              </ListGroup.Item>
            </NavLink>
          </ListGroup>
        </Card.Body>
      </Card>
    </Main>
  )
}